import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { DialogActions, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { LinearProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { DialogContentText, IconButton } from "@material-ui/core";
import { CustomFC } from "../../constants/types";
import { useIntl } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../CustomButton/CustomButton";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancel: {
            [theme.breakpoints.only("xs")]: {
                width: "40%",
            },
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(2.3),
            top: theme.spacing(2),
            color: "grey",
        },
        content: {
            margin: "-1em 0 1em 0",
        },
        contentText: {
            fontSize: 13,
            color: "#616161",
        },
        title: { fontSize: "16px", fontWeight: 500 },
        loader: {
            backgroundColor: COLORS.primaryTransparent,
        },
        loaderBar: {
            backgroundColor: COLORS.primaryDark,
        },
    })
);

interface CustomDialogProps {
    title: string;
    text?: string;
    open: boolean;
    loading?: boolean;
    dialogActions?: React.ComponentElement<any, any>;
    dialogTitleComponent?: React.ReactElement;
    fullWidth?: boolean;
    fullScreen?: boolean;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
    handleClose(): void;
    handleSubmit?(): void;
    submitText?: string;
    disableSubmit?: boolean;
    cancelText?: string;
    style?: React.CSSProperties;
    buttonWidth?: string;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog: FC<CustomDialogProps & CustomFC> = (props) => {
    const {
        title,
        text,
        open,
        loading,
        dialogActions,
        children,
        fullWidth,
        fullScreen,
        maxWidth,
        handleClose,
        handleSubmit,
        submitText,
        cancelText,
        disableSubmit,
        style,
        buttonWidth,
    } = props;

    const { formatMessage } = useIntl();
    const classes = useStyles();

    return (
        <Dialog
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            maxWidth={maxWidth || "sm"}
            open={open}
            keepMounted
            style={style}
        >
            {loading && (
                <LinearProgress
                    classes={{
                        colorPrimary: classes.loader,
                        barColorPrimary: classes.loaderBar,
                    }}
                />
            )}
            <DialogTitle>
                <span className={classes.title}>{title}</span>
                <IconButton
                    className={classes.closeButton}
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <hr />
            </DialogTitle>

            <DialogContent className={classes.content}>
                <DialogContentText className={classes.contentText}>
                    {text}
                </DialogContentText>
                {children}

                {dialogActions && (
                    <DialogActions className="p-0 mt-4">
                        {dialogActions}
                    </DialogActions>
                )}

                <DialogActions className="p-0 mt-4">
                    <CustomButton
                        onClick={handleClose}
                        text={
                            cancelText ||
                            formatMessage({
                                id: "common.cancel",
                            })
                        }
                        loading={loading}
                        disabled={loading}
                        isCancel
                    />
                    {handleSubmit && (
                        <CustomButton
                            onClick={handleSubmit}
                            text={
                                submitText ||
                                formatMessage({
                                    id: "common.confirm",
                                })
                            }
                            loading={loading}
                            disabled={loading || disableSubmit}
                            style={{ margin: 0, width: buttonWidth || "35%" }}
                        />
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default CustomDialog;
