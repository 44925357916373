import { Avatar, WithWidthProps } from "@material-ui/core";
import { Button, Dropdown, Menu } from "antd";
import { FC, useContext, useState } from "react";
import { Typography, withWidth } from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { clearStorage } from "../../utils/clearStorage";
import { ROUTES } from "../../constants/routes";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import { useIntl } from "react-intl";
import { EncryptionKeyContext } from "../../providers/EncryptionKeyProvider/EncryptionKeyProvider";
import CustomPartnerStatus from "../CustomPartnerStatus/CustomPartnerStatus";
import config from "../../config/config";
import clsx from "clsx";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UserBadge from "../UserBadge/UserBadge";
import CustomDialog from "../CustomDialog/CustomDialog";
import useStyles from "./useStyles";
import { tracerkeyDisconnect } from "../../services/tracerkey";

const UserMenu: FC<WithWidthProps & RouteComponentProps> = ({ width }) => {
    const classes = useStyles();
    const { me } = useContext(UserContext);
    const { handleOpen } = useContext(EncryptionKeyContext);
    const { formatMessage, locale } = useIntl();
    const [logoutModal, setLogoutModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleLogout = async () => {
        setLoader(true);
        clearStorage().then(async () => {
            tracerkeyDisconnect().then(() => {
                window.location.href = `${
                    config?.id?.baseUrl
                }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`;
            });
        });
        // clearStorage().then(() => {
        //     setLogoutClick(true);
        //     setTimeout(() => {
        //         window.location.href = ROUTES.LOGOUT;
        //         setLogoutClick(false);
        //     }, 5000);
        // });
    };

    const manageAccount = () => {
        window.location.href = `${
            config?.id?.baseUrl
        }/settings?next_url=${`${config.uiUrl}${ROUTES.DASHBOARD}&logout_url=${config?.uiUrl}${ROUTES.LOGOUT}`}`;
    };

    return (
        <>
            <div className={classes.root}>
                <Dropdown
                    placement={
                        width === "sm" || width === "xs"
                            ? "bottomRight"
                            : "bottom"
                    }
                    overlayStyle={{ zIndex: 10000 }}
                    overlay={() => (
                        <Menu className={clsx(classes.menu, "p-0")}>
                            <div
                                className={clsx(classes.badgeCont, "px-4 py-3")}
                            >
                                <UserBadge />
                                <Typography
                                    variant="subtitle1"
                                    className={classes.font14}
                                >
                                    {me?.email || ""}
                                </Typography>{" "}
                            </div>
                            <hr className="mt-0 mb-0" />
                            <Menu.Item
                                key="1"
                                className={clsx("px-3 py-2")}
                                onClick={
                                    !me?.activated ? handleOpen : undefined
                                }
                            >
                                <CustomPartnerStatus
                                    activated={!!me?.activated}
                                    isMyProfile
                                />
                            </Menu.Item>
                            <hr className="mt-0 mb-0" />
                            <Menu.Item
                                key="2"
                                className={clsx("px-3 py-2")}
                                onClick={manageAccount}
                            >
                                <AccountCircle
                                    fontSize="small"
                                    className="mr-2"
                                />{" "}
                                {formatMessage({ id: "auth.manage.account" })}
                            </Menu.Item>
                            <Menu.Item
                                key="3"
                                className={clsx("px-3 py-2")}
                                onClick={() => setLogoutModal(true)}
                            >
                                <ExitToAppIcon
                                    fontSize="small"
                                    className="mr-2"
                                />{" "}
                                {formatMessage({ id: "auth.logout" })}
                            </Menu.Item>
                        </Menu>
                    )}
                    trigger={["click"]}
                    arrow
                >
                    <Button type="text" className={classes.iconBtn}>
                        <Avatar alt="Travis Howard" className={classes.avatar}>
                            <AccountCircle className={classes.accountCircle} />
                        </Avatar>
                        <Typography
                            variant="body2"
                            noWrap
                            className={classes.email}
                        >
                            {me?.email || ""}
                        </Typography>{" "}
                        <span>
                            <MdExpandMore
                                className={clsx(classes.expandIcon, "ml-1")}
                            />
                        </span>
                    </Button>
                </Dropdown>
            </div>
            <CustomDialog
                loading={loader}
                open={logoutModal}
                handleClose={() => setLogoutModal(false)}
                title={formatMessage({ id: "logout.header" })}
                submitText={formatMessage({ id: "logout.button" })}
                handleSubmit={handleLogout}
                disableSubmit={false}
                fullWidth
                buttonWidth={locale === "fr" ? "35%" : "25%"}
            >
                <Typography variant="subtitle1" className={classes.logout}>
                    {formatMessage({ id: "logout.text" })}
                </Typography>
            </CustomDialog>
        </>
    );
};

export default withWidth()(withRouter(UserMenu));
