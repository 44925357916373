const partnership = {
    "partnership.tab.partners": "Partners",
    "partnership.tab.invitations": "Invitations",
    "partnership.invitation.invite": "Invite New Partner",
    "partnership.invitation.title.common.all": "All Invitations",
    "partnership.invitation.title.common.pending": "Pending Invitations",
    "partnership.invitation.title.common.accepted": "Accepted Invitations",
    "partnership.invitation.title.common.rejected": "Rejected Invitations",
    "partnership.invitation.title.common.canceled": "Canceled Invitations",
    "partnership.invitation.new.partner.title": "New partner",
    "partnership.invitation.new.partner.subtitle":
        "Send a partnership invitation to a TracerDoc user, or invite a new user to register in TracerDoc and be your partner.",
    "partnership.invitation.new.partner.not.registred":
        "This user is not registred in TracerDoc, invite via email ?",
    "partnership.partners.title": "List of your partners",
    "partnership.cancel.title": "Cancel Partnership Invitation",
    "partnership.cancel.text":
        "Are you sure you want to cancel your partnership invitation to {partner}",
    "partnership.accept.title": "Accept Partnership Invitation",
    "partnership.accept.text":
        "Accept the invite to become partner with {partner}",
    "partnership.reject.title": "Decline Partnership Invitation",
    "partnership.reject.text":
        "Are you sure you want to decline the partnership invitation from {partner}",
    "partnership.confirm.invite.cancel": "Yes, confirm",
    "partnership.confirm.invite.accept": "Accept",
    "partnership.confirm.invite.reject": "Decline",
    "partnership.confirm.delete.1":
        "Are you sure you want to delete this partnership with ? ",
    "partnership.confirm.delete.2":
        "You will not be able anymore to send or receive file from/to this partner.",
    "partnership.searchPartner": "Search partner...",
};

export default partnership;
