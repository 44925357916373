const backup = {
    "backup.download.title": "Sauvegarde de documents",
    "backup.download.subtitle":
        "Téléchargez tous vos documents cryptés (envoyés et reçus) dans un fichier zip.",
    "backup.recover.file.dragtext":
        "Cliquez ou faites glisser le fichier dans cette zone pour télécharger votre fichier TracerDoc (.tdFile)",
    "backup.recover.zip.dragtext":
        "Cliquez ou faites glisser le fichier dans cette zone pour télécharger votre fichier TracerDoc (.zip)",
    "backup.recover.key.dragtext":
        "Cliquez ou faites glisser le fichier dans cette zone pour télécharger votre clé TracerDoc (.tdKey)",
    "backup.in.progress": "Sauvegarde En Cours",
    "backup.email.on.completion":
        "Un e-mail vous sera envoyé une fois la sauvegarde terminée.",
    "backup.download.button.title": "Télécharger",
    "backup.package.available.1":
        "YVotre package de sauvegarde est disponible et prêt à être téléchargé. ",
    "backup.package.available.2": "Ce package sera disponible pendant ",
    "backup.package.available.3": "et sera automatiquement supprimé.",
    "backup.confirm.package.delete":
        "Êtes-vous sûr de vouloir supprimer votre package de sauvegarde? Ce processus ne peut pas être annulé.",
    "backup.start": "Démarrer la sauvegarde",
    "backup.tab.backup": "Sauvegarde",
    "backup.tab.recover": "Récupération",
    "backup.recover.title": "Récupération de documents",
    "backup.recover.upload.tracerdoc.file": "Téléverser le fichier Tracerdoc",
    "backup.recover.upload.tracerdoc.key": "Téléverser la clé Tracerdoc",
    "backup.recover.subtitle":
        "Récupérez vos documents sauvegardés en important votre fichier et la clé appropriée.",
};

export default backup;
