import { FC } from "react";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, Typography, Paper } from "@material-ui/core";
import useStyles from "./styles";
import transferFiles from "../../images/files.svg";

const Banner: FC = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Paper elevation={0} className={classes.banner}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography
                        variant="h5"
                        style={{ color: "#fff" }}
                        className="mt-2"
                    >
                        {formatMessage({
                            id: "dashboard.banner.welcome",
                        })}
                    </Typography>
                    <div className="mt-3">
                        <Typography
                            variant="subtitle1"
                            style={{ color: "#fefefe" }}
                        >
                            {formatMessage({
                                id: "dashboard.banner.welcome.subtitle1",
                            })}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="d-flex justify-content-end ">
                        <div className={classes.imgContainer}>
                            <img
                                src={transferFiles}
                                className={classes.welcomeImg}
                                alt="welcome"
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withRouter(Banner);
