const categories = {
    "categories.title": "Categories",
    "categories.category": "Category",
    "categories.subcategory": "Subcategory",
    "categories.add.category": "Add category",
    "categories.add.subcategory": "Add subcategory",
    "categories.create.title": "Category creation",
    "categories.add.new": "Add New Category",
    "categories.add.new.subcategory": "New subcategory for",
    "categories.edit": "Edit category",
    "categories.all": "All Categories",
    "categories.name": "Name",
    "categories.actions": "Actions",
    "categories.confirm.bulk.delete": "Confirm Bulk Delete",
    "categories.confirm.delete.1": "Are you sure you want to delete {item}?",
    "categories.confirm.delete.2": "This process cannot be undone.",
    "categories.create.subtitle": "Enter informations for your new category",
    "categories.add.success": "New category has been added successfully",
    "categories.rename.success": "Renamed successfully",
    "subcategories.create.title": "Subcategory creation",
    "subcategories.create.subtitle":
        "Enter informations for your new subcategory",
    "subcategories.add.success": "New subcategory has been added successfully",
};

export default categories;
