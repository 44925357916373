const plan = {
    "plan.title": "Plan d'abonnement",
    "plan.subtitle":
        "Tarification simple. Pas de frais cachés. Fonctionnalités avancées.",
    "plan.monthly": "Mensuel",
    "plan.yearly": "Annuel",
    "plan.subscription": "Abonnement",
    "plan.plan": "Plan",
    "plan.manage": "Gérer l'abonnement",
};

export default plan;
