import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LOCAL_STORAGE_KEYS } from "../../../constants/localStorage";
import { AiFillFileZip } from "react-icons/ai";
import { Alert, Button, Space } from "antd";
import { sweetalert } from "../../../utils/sweetalert";
import GetAppIcon from "@material-ui/icons/GetApp";
import config from "../../../config/config";
import TableLoader from "../../../components/TableLoader/TableLoader";
import useStyles from "../styles";
import ConfirmDelete from "../../../components/ConfirmDelete/ConfirmDelete";
import Countdown from "react-countdown";
import Fade from "react-reveal/Fade";

type IBackupResult = {
    id: string;
    requestedOn: string;
    willBeDeletedOn: string;
    status: string;
} | null;

const BackUpContainer = ({ children }: { children: ReactNode }) => {
    const { formatMessage } = useIntl();
    return (
        <div className="pt-4 d-flex flex-column align-items-center">
            <Fade bottom distance="25%">
                <Typography variant="h5" className="font-weight-bold mt-3">
                    {formatMessage({ id: "backup.download.title" })}
                </Typography>
            </Fade>
            <Fade bottom distance="25%" delay={150}>
                {children}
            </Fade>
        </div>
    );
};

const Backup = () => {
    const { formatMessage } = useIntl();
    const [loading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [localToken] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
    );
    const classes = useStyles();
    const [backupResult, setBackupResult] = useState<IBackupResult>();

    const cb = useCallback(
        async function getBackupRequest() {
            if (!localToken || backupResult !== undefined) return;
            return await fetch(config.backupEndpoint, {
                headers: { Authorization: `Bearer ${localToken}` },
            })
                .then((res) => res.json())
                .then((res) => setBackupResult(res.data));
        },
        [localToken, backupResult]
    );

    useEffect(() => {
        cb();
    }, [cb]);

    const deletePackage = () => {
        setDeleteLoader(true);

        fetch(config.backupEndpoint, {
            headers: localToken
                ? { Authorization: `Bearer ${localToken}` }
                : undefined,
            method: "DELETE",
        })
            .then((res) => {
                if (res.ok) {
                    setBackupResult(undefined);
                    setDeleteLoader(false);
                    setOpenDeleteModal(false);
                }
            })
            .catch((err) => {
                setDeleteLoader(false);
                sweetalert("error", "Error", err.message);
            });
    };

    // const handleDownload = () => {
    //     setLoading(true);
    //     fetch(config.backupEndpoint, {
    //         headers: localToken
    //             ? { Authorization: `Bearer ${localToken}` }
    //             : undefined,
    //     })
    //         .then((res) => {
    //             const header = res.headers.get("Content-Disposition");
    //             const parts = header!.split(";");
    //             const filename = parts[1].split("=")[1];
    //             res.blob()
    //                 .then((blob) => {
    //                     var fileURL = window.URL.createObjectURL(blob);
    //                     const tempLink = document.createElement("a");
    //                     tempLink.href = fileURL;
    //                     tempLink.setAttribute("download", filename);
    //                     tempLink.click();
    //                     setLoading(false);
    //                 })
    //                 .catch((err) => {
    //                     console.log("err : ", err);
    //                     setLoading(false);
    //                 });
    //         })
    //         .catch((err) => {
    //             console.log("err : ", err);
    //             setLoading(false);
    //         });
    // };

    if (backupResult === undefined) {
        return <TableLoader />;
    }

    if (backupResult !== null) {
        if (backupResult.status === "PROCESSING") {
            return (
                <BackUpContainer>
                    <Grid container justifyContent="center" className="mt-4">
                        <Grid item xs={12} sm={6}>
                            <LinearProgress
                                classes={{
                                    colorPrimary: classes.loader,
                                    barColorPrimary: classes.loaderBar,
                                }}
                            />
                            <Alert
                                showIcon
                                message={
                                    <b>
                                        {formatMessage({
                                            id: "backup.in.progress",
                                        })}
                                    </b>
                                }
                                description={formatMessage({
                                    id: "backup.email.on.completion",
                                })}
                                type="info"
                            />
                        </Grid>
                    </Grid>
                </BackUpContainer>
            );
        } else if (backupResult.status === "DONE") {
            return (
                <BackUpContainer>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={7}>
                            <Typography
                                variant="subtitle1"
                                className="mt-1"
                                align="center"
                            >
                                {formatMessage({
                                    id: "backup.package.available.1",
                                })}{" "}
                                <br />{" "}
                                {formatMessage({
                                    id: "backup.package.available.2",
                                })}{" "}
                                <Countdown
                                    date={
                                        new Date(backupResult?.willBeDeletedOn)
                                    }
                                    renderer={({
                                        days,
                                        hours,
                                        minutes,
                                        seconds,
                                    }) => {
                                        return (
                                            <span
                                                style={{
                                                    color: "#0D47A1",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {days ? `${days}d` : ""}{" "}
                                                {hours ? `${hours}h` : ""}{" "}
                                                {minutes
                                                    ? `${minutes} min${
                                                          minutes > 1 ? "s" : ""
                                                      }`
                                                    : ""}{" "}
                                                {seconds} sec
                                                {seconds > 1 ? "s" : ""}
                                            </span>
                                        );
                                    }}
                                />{" "}
                                {formatMessage({
                                    id: "backup.package.available.3",
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <a
                        href={`${config.backupEndpoint}/${backupResult.id}`}
                        rel="noreferrer"
                    >
                        <Box
                            className={classes.box}
                            style={{ cursor: "pointer" }}
                        >
                            <AiFillFileZip className={classes.icon} />
                            <span className="mt-1">.zip</span>
                        </Box>
                    </a>
                    <Space
                        direction="vertical"
                        className="d-flex align-items-center"
                    >
                        <Button
                            type="link"
                            size="large"
                            className={classes.downloadBtn}
                            // target="_blank"
                            rel="noreferrer"
                            href={`${config.backupEndpoint}/${backupResult.id}`}
                        >
                            <GetAppIcon
                                className="mr-1"
                                style={{ fontSize: 21 }}
                            />
                            {formatMessage({
                                id: "backup.download.button.title",
                            })}
                        </Button>
                        <Typography variant="caption">
                            <span
                                style={{
                                    color: "#0D47A1",
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpenDeleteModal(true)}
                            >
                                {formatMessage({
                                    id: "backup.delete.package.1",
                                })}{" "}
                            </span>
                            {formatMessage({ id: "backup.delete.package.2" })}
                        </Typography>
                    </Space>
                    <ConfirmDelete
                        open={openDeleteModal}
                        toggleModal={setOpenDeleteModal}
                        submit={deletePackage}
                        loading={deleteLoader}
                        message={formatMessage({
                            id: "backup.confirm.package.delete",
                        })}
                    />
                </BackUpContainer>
            );
        }
        return <p>{"unknown"}</p>;
    }

    return (
        <BackUpContainer>
            <Typography variant="subtitle1" className="mt-1">
                {formatMessage({ id: "backup.download.subtitle" })}
            </Typography>
            <Box className={classes.box}>
                <AiFillFileZip className={classes.icon} />
                <span className="mt-1">.zip</span>
            </Box>
            <Button
                onClick={() => {
                    fetch(config.backupEndpoint, {
                        headers: localToken
                            ? {
                                  Authorization: `Bearer ${localToken}`,
                              }
                            : undefined,
                        method: "POST",
                        mode: "cors",
                    }).then((res) => {
                        if (res.status === 204) setBackupResult(undefined);
                    });
                }}
                size="large"
                className={classes.downloadBtn}
                loading={loading}
                disabled={loading}
            >
                <GetAppIcon className="mr-1" style={{ fontSize: 21 }} />
                {formatMessage({ id: "backup.start" })}
            </Button>
        </BackUpContainer>
    );
};

export default Backup;
