import { FC, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import { ApolloError, useQuery } from "@apollo/client";
import { GET_CURRENT_STATE } from "../../graphql/license/query";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { useIntl } from "react-intl";
import { Skeleton } from "@material-ui/lab";
import { GetMyPlan } from "../../graphql/user/types/GetMyPlan";
import { GET_MY_PLAN } from "../../graphql/user/query";
import { GetCurrentState } from "../../graphql/license/types/GetCurrentState";
import PageContainer from "../../components/PageContainer/PageContainer";
import Banner from "./Banner";
import Storage from "./Storage";
import Chart from "react-apexcharts";
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import useStyles from "./styles";

const Dashboard: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const { data, loading: loader } = useQuery<GetCurrentState>(
        GET_CURRENT_STATE,
        { onError: (err) => onFetchError(err) }
    );

    const { data: { myPlan } = {} } = useQuery<GetMyPlan>(GET_MY_PLAN, {
        onError: (err) => onFetchError(err),
    });

    const { currentState } = data || {};
    const { license } = currentState || {};

    const roundNumber = (num: number | undefined | null) => {
        if (!num) return 0;
        return Math.round((Number(num / 1000000) + Number.EPSILON) * 100) / 100;
    };

    // const publicVolume: number[] = [
    //     roundNumber(currentState?.pubFilesize),
    //     (license?.pubFilesizeMonthly || 0) -
    //         roundNumber(currentState?.pubFilesize),
    // ];

    const privateVolume: number[] = [
        roundNumber(currentState?.prvFilesize),
        (license?.prvFilesizeMonthly || 0) -
            roundNumber(currentState?.prvFilesize),
    ];

    // const volumeUsed: number[] = [
    //     roundNumber(currentState?.pubFilesize),
    //     roundNumber(currentState?.prvFilesize),
    // ];

    const onFetchError = (err: ApolloError) => {
        displaySnackBar({
            message: err.message || formatMessage({ id: "error.unknown" }),
            type: "error",
        });
    };

    const options: ApexOptions = {
        chart: {
            type: "donut",
            selection: { enabled: false, stroke: { width: 0 } },
        },
        colors: ["#2E93fA", "#fff"],
        legend: { position: "bottom", show: false },
        dataLabels: { enabled: false },
        title: {
            align: "center",
            text: formatMessage({ id: "dashboard.volume.public" }),
        },
        stroke: { colors: ["#CCDCF8"], width: 1 },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: "12px",
                            color: "#616161",
                        },
                        value: {
                            show: true,
                            fontSize: "16px",
                            offsetY: -6,
                            formatter: (val) => `${val} MB`,
                        },
                        total: {
                            show: true,
                            fontSize: "16px",
                            formatter: function ({ globals }) {
                                const total = Number(
                                    parseFloat(
                                        `${
                                            globals.seriesTotals.reduce(
                                                (a: any, b: any) => a + b,
                                                0
                                            ) || 0
                                        }`
                                    ).toFixed(2)
                                );
                                return `${total} ${
                                    total >= 1000 ? "GB" : "MB"
                                }`;
                            },
                        },
                    },
                },
            },
        },
    };

    // const publicVolumeOptions = {
    //     ...options,
    //     tooltip: { enabled: false },
    //     labels: ["Used", "Available"],
    // };

    const privateVolumeOptions = {
        ...options,
        colors: ["#FFAA2C", "#fff"], // FFAA2C 41DB9A
        tooltip: { enabled: false },
        expandOnClick: false,
        stroke: { colors: ["#FFE0B2"], width: 1 },
        title: {
            ...options.title,
            text: formatMessage({ id: "dashboard.volume.private" }),
        },
        labels: ["Used", "Available"],
    };

    // const volumeUsedOptions: ApexOptions = {
    //     ...options,
    //     colors: ["#2E93fA", "#FFAA2C"],
    //     dataLabels: { enabled: true },
    //     labels: [
    //         formatMessage({ id: "dashboard.pub.file.size" }),
    //         formatMessage({ id: "dashboard.priv.file.size" }),
    //     ],
    //     legend: { position: "bottom", show: true },
    //     fill: { type: "gradient" },
    //     title: {
    //         ...options.title,
    //         text: formatMessage({ id: "dashboard.volume.storage" }),
    //     },
    //     tooltip: {
    //         custom: ({ series, seriesIndex }) => {
    //             return `${formatMessage({
    //                 id: `dashboard.${
    //                     seriesIndex === 0 ? "pub" : "priv"
    //                 }.file.size`,
    //             })} : ${parseFloat(`${series?.[seriesIndex]}`).toFixed(2)} MB`;
    //         },
    //     },
    // };

    return (
        <PageContainer title="Dashboard">
            <div className="d-flex flex-column">
                {loader ? (
                    <div className="mt-4">
                        <Skeleton
                            variant="rect"
                            width="100%"
                            height={180}
                            animation="wave"
                        />
                        <Skeleton
                            className={clsx("mt-4", classes.loader)}
                            variant="rect"
                            width="100%"
                            height={270}
                            animation="wave"
                        />
                        <Skeleton
                            className={clsx("mt-4", classes.loader)}
                            variant="rect"
                            width="100%"
                            height={100}
                            animation="wave"
                        />
                    </div>
                ) : (
                    <>
                        <Grid container spacing={2} className="mb-2">
                            <Grid item xs={12} className="mt-3">
                                <Fade bottom distance="25%">
                                    <Banner />
                                </Fade>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                className={classes.charts}
                            >
                                <div>
                                    <Fade bottom distance="50%" delay={150}>
                                        <Storage
                                            // type="public"
                                            type="private"
                                            myPlan={myPlan}
                                            currentState={currentState}
                                            // items={
                                            //     myPlan
                                            //         ? [
                                            //               "pubFileCount",
                                            //               "prvFileCount",
                                            //           ]
                                            //         : ["pubFileCount"]
                                            // }
                                            items={["prvFileCount"]}
                                        />
                                    </Fade>
                                </div>

                                {/* <div className="mt-3 d-flex flex-row">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={myPlan ? 6 : 8}>
                                        <Fade bottom distance="50%" delay={350}>
                                            <Paper
                                                variant="outlined"
                                                className={clsx(
                                                    "p-4 h-100 d-flex justify-content-center",
                                                    classes.paperBoxShadow
                                                )}
                                            >
                                                <Chart
                                                    options={
                                                        publicVolumeOptions
                                                    }
                                                    series={publicVolume}
                                                    type="donut"
                                                    height={"200"}
                                                    width={"300"}
                                                />
                                            </Paper>
                                        </Fade>
                                    </Grid>
                                    {myPlan && (
                                        <Grid item xs={12} sm={6}>
                                            <Fade
                                                bottom
                                                distance="50%"
                                                delay={550}
                                            >
                                                <Paper
                                                    variant="outlined"
                                                    className={clsx(
                                                        "p-4 h-100 d-flex justify-content-center",
                                                        classes.paperBoxShadow
                                                    )}
                                                >
                                                    <Chart
                                                        options={
                                                            privateVolumeOptions
                                                        }
                                                        series={privateVolume}
                                                        type="donut"
                                                        height={"200"}
                                                        width={"300"}
                                                    />
                                                </Paper>
                                            </Fade>
                                        </Grid>
                                    )}
                                </Grid>
                            </div> */}
                            </Grid>
                            {/* {!loader && myPlan && ( */}
                            {/* {!loader && true && (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={clsx("pt-4", classes.charts)}
                            >
                                <Fade bottom distance="20%">
                                    <Paper
                                        variant="outlined"
                                        className={clsx(
                                            "w-100 py-4 h-100",
                                            classes.paperBoxShadow
                                        )}
                                    >
                                        <Chart
                                            options={volumeUsedOptions}
                                            series={volumeUsed}
                                            type="donut"
                                            height={"380"}
                                        />
                                    </Paper>
                                </Fade>
                            </Grid>
                        )} */}
                        </Grid>
                        <Grid container spacing={2} className="pb-0">
                            <Grid item xs={12} md={8}>
                                <Fade bottom distance="50%" delay={550}>
                                    <Paper
                                        variant="outlined"
                                        className={clsx(
                                            "p-4 h-100 d-flex justify-content-center",
                                            classes.paperBoxShadow
                                        )}
                                    >
                                        <Chart
                                            options={privateVolumeOptions}
                                            series={privateVolume}
                                            type="donut"
                                            height={"200"}
                                            width={"300"}
                                        />
                                    </Paper>
                                </Fade>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
        </PageContainer>
    );
};

export default withRouter(Dashboard);
