const plan = {
    "plan.title": "Subscription Plan",
    "plan.subtitle": "Simple pricing. No hidden fees. Advanced features.",
    "plan.monthly": "Monthly",
    "plan.yearly": "Yearly",
    "plan.subscription": "Subscription",
    "plan.plan": "Plan",
    "plan.manage": "Manage subscription",
};

export default plan;
