const document = {
    "document.list.title": "Liste des documents",
    "document.transfer.title": "Transfert de documents",
    "document.transfer.submit": "Crypter, Charger et Transférer",
    "document.personal.submit": "Crypter et télécharger",
    "document.list.decrypt.download": "Décrypter et Télécharger",
    "document.list.decrypt.download.tooltip":
        "Décrypter et Télécharger le document",
    "document.decryption.title": "Décryptage",
    "document.decryption.section.title": "Décryptage simples de documents",
    "document.decryption.subtitle":
        "Ici, vous pouvez simplement décrypter un fichier. Veuillez noter que seuls les fichiers cryptés par TracerDoc (fichiers .tracerdoc) peuvent être déchiffrés ici.",
    "document.encryption.submit": "Crypter et Télécharger",
    "document.decryption.submit": "Décrypter",
    "document.list.download": "Télécharger",
    "document.list.download.tooltip": "Télécharger le document crypté",
    "document.encryption.title": "Cryptage de document",
    "document.encryption.subtitle":
        "Choisissez un document et cryptez-le avec la clé de votre partenaire.",
    "document.transfer.mode": "Mode de transfert",
    "document.transfer.mode.upload.desc":
        "Envoyer le document crypté via IPFS et le transférer au partenaire.",
    "document.transfer.mode.download.desc":
        "Téléchargez simplement le document crypté.",
    "document.transfer.mode.upload": "Télécharger",
    "document.transfer.mode.upload.and.transfer": "Charger et Transférer",
    "document.transfer.mode.download": "Téléchargement simple",
    "document.transfer.type.public": "Publique",
    "document.transfer.type.private.ipfs": "Private IPFS",
    "document.transfer.type.private": "Privée",
    "document.transfer.type": "Type d'IPFS",
    "documents.all": "Tous les documents",
    "document.public.storage.max.limit":
        "Le stockage public a atteint sa limite maximale de fichiers !",
    "document.private.storage.max.limit":
        "Le stockage privé a atteint sa limite maximale de fichiers ! Améliorez votre abonnement pour augmenter votre volume.",
    "document.upgrade.license":
        "Améliorez votre licence pour téléverser et transférer des documents.",
    "document.download.decrypt": "Télécharger et décrypter",
    "document.properties": "Propriétés du document",
    "document.fileName": "Nom du fichier",
    "document.fileSize": "Taille",
    "document.securityLevel": "Niveau de sécurité",
    "document.ipfsType": "Type IPFS",
    "document.cid": "CID du document",
    "document.sender.cid": "CID de la clé de l'expéditeur",
    "document.recipient.cid": "CID de la clé du destinataire",
    "document.created": "Créé",
    "document.expiresAt": "Expirera le",
    "document.modified": "Modifié",
    "document.category": "Catégorie",
    "document.subcategory": "Sous-catégorie",
    "document.categories": "Catégories",
    "document.sender": "Expéditeur",
    "document.recipient": "Destinataire",
    "document.status": "Statut",
    "document.transfer.new": "Ajouter un Nouveau Document",
    "document.download.url": "URL de téléchargement",
    "document.remove.filter":
        "Cliquez pour supprimer le filtre pour afficher toute la liste",
    "document.alert.remove.filter":
        "Cliquez sur l'icône de filtre ci-dessous pour afficher la liste des transferts de documents.",
    "document.download.only": "Téléchargement uniquement",
    "document.select.action": "Sélectionner une action",
    "document.upload.files": "Téléverser le fichier et la clé Tracerdoc",
    "document.upload.zip": "Téléverser un fichier ZIP",
    "documents.personal": "Stockage interne",
    "document.no.storage": "Aucun stockage",
    "document.add.new.modal.title": "Ajouter un nouveau document",
    "document.download.encrypted.file": "Télécharger le fichier chiffré",
    "document.store.encrypted.file": "Stocker le fichier chiffré",
    "document.store.share.encrypted.file":
        "Stocker et partager le fichier chiffré",
    "document.download.shareable.file":
        "Télécharger le fichier chiffré partageable",
    "document.remove": "Supprimer le fichier",
    "document.no.partner": "Aucun partenaire - Uniquement pour moi",
    "document.duration": "Expiration",
    "document.unlimited": "Illimité",
    "document.d001": "1 jour (24 heures)",
    "document.d003": "3 jours (72 heures)",
    "document.d007": "7 jours (168 heures)",
    "document.d030": "30 jours (720 heures)",
    "document.permanent.storage": "* Stockage permanent",
    "document.storage.after.upload":
        "Stockage disponible après téléversement :",
    "document.auto.remove.activated": "* Suppression automatique activée le",
    "document.not.available.free.license":
        "Non disponible avec la licence gratuite",
    "document.confirm.delete.1": "Êtes-vous sûr de vouloir supprimer ",
    "document.confirm.delete.2":
        "Cela sera définitivement supprimé pour vous et vos partenaires.",
};

export default document;
