import { makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
    },
    iconBtn: {
        padding: "0 1em 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: COLORS.lightblue,
        borderRadius: 25,
        [theme.breakpoints.only("xs")]: {
            background: "transparent",
        },
    },
    email: {
        fontSize: 14,
        marginLeft: "4px",
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
    expandIcon: {
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
        color: "#b7c0cd",
        marginTop: "-3px",
    },
    logout: {
        marginTop: "-0.5em",
        color: "#424242",
        marginBottom: "1.5em",
    },
    menu: {
        border: "1px solid #fafafa",
        width: "240px",
        zIndex: 2,
    },
    badgeCont: {
        backgroundColor: " #e7eff9",
        backgroundImage: "linear-gradient(315deg, #e7eff9 0%, #e7eff9 74%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    font14: { fontSize: 14 },
    avatar: {
        height: "38px",
        width: "38px",
        border: "1px solid #fff",
        background: COLORS.lightblue,
    },
    accountCircle: {
        fontSize: 26,
        color: "#353B40",
    },
}));

export default useStyles;
