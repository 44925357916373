import { FC, useContext, Fragment } from "react";
import { useMutation } from "@apollo/client";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core";
import { Typography, Paper, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ROUTES } from "../../../constants/routes";
import { Badge } from "react-bootstrap";
import { Button, Space } from "antd";
import { FaArrowCircleRight } from "react-icons/fa";
import { useIntl } from "react-intl";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { GET_PORTAL_SESSION } from "../../../graphql/plan/mutation";
import { GetPortalSession } from "../../../graphql/plan/types/GetPortalSession";
import { GetPortalSessionVariables } from "../../../graphql/plan/types/GetPortalSession";
import config from "../../../config/config";
import { useQuery } from "@apollo/client";
import { GET_MY_PLAN } from "../../../graphql/user/query";
import { GetMyPlan } from "../../../graphql/user/types/GetMyPlan";
import CustomPaper from "../../../components/CustomPaper/CustomPaper";
import PricingTable from "../../Plan/PricingTable/PricingTable";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles(() =>
    createStyles({
        font18: { fontSize: 18 },
        btn: {
            marginTop: "1.5em",
            borderRadius: 4,
            background: "#0074D4",
        },
    })
);

interface IPricingTable {
    type: string;
    desc?: string;
    tableId: string | undefined;
}

const Subscription: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);

    const { data, loading } = useQuery<GetMyPlan>(GET_MY_PLAN, {
        onError: (err) => {
            displaySnackBar({
                message:
                    err.message ||
                    formatMessage({
                        id: "error.unknown",
                    }),
                type: "error",
            });
        },
    });

    const { amount, interval, name, currency } = data?.myPlan || {};

    const [portalSession] = useMutation<
        GetPortalSession,
        GetPortalSessionVariables
    >(GET_PORTAL_SESSION, {
        onCompleted: (data) => {
            if (data?.portalSession) {
                window.location.href = data?.portalSession;
            }
        },
        onError: (error) => {
            displaySnackBar({
                type: "error",
                message: formatMessage({
                    id: error.message || "error.unknown",
                }),
            });
        },
    });

    const manageSubscription = () => {
        const returnUrl = `${config.uiUrl}${ROUTES.MY_ACCOUNT}`;
        portalSession({ variables: { returnUrl } });
    };

    const pricingTables: IPricingTable[] = [
        {
            type: "Business",
            desc: "",
            tableId: config.stripe.businessPricingTableId,
        },
    ];
    return (
        <>
            {loading ? (
                <Skeleton
                    variant="rect"
                    height={200}
                    width={600}
                    animation="pulse"
                    className="mt-4 rounded"
                />
            ) : (
                <>
                    {data?.myPlan ? (
                        <>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Fade bottom distance="25%">
                                        <Paper className="mt-4 w-100 p-4">
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Space
                                                        size={0}
                                                        direction="vertical"
                                                        className="justify-content-between h-100 pt-2 w-100"
                                                    >
                                                        <Space>
                                                            <Typography
                                                                variant="h6"
                                                                className="font-weight-bold"
                                                            >
                                                                <Badge
                                                                    bg="dark"
                                                                    text="light"
                                                                    className="mr-2"
                                                                >
                                                                    {name}
                                                                </Badge>
                                                                <span
                                                                    className={
                                                                        classes.font18
                                                                    }
                                                                >
                                                                    {formatMessage(
                                                                        {
                                                                            id: "plan.plan",
                                                                        }
                                                                    )}
                                                                </span>
                                                            </Typography>
                                                        </Space>
                                                        <Space size={3}>
                                                            <Typography variant="h5">
                                                                {currency ===
                                                                "eur" ? (
                                                                    <span>
                                                                        {" "}
                                                                        &euro;
                                                                    </span>
                                                                ) : (
                                                                    "$"
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                variant="h3"
                                                                className="font-weight-bold mt-2"
                                                            >
                                                                {amount
                                                                    ? amount /
                                                                      100
                                                                    : "0"}
                                                            </Typography>
                                                            <Typography
                                                                variant="h6"
                                                                className="mt-3"
                                                                color="textSecondary"
                                                            >
                                                                /{interval}
                                                            </Typography>
                                                        </Space>
                                                        <div>
                                                            <Button
                                                                size="large"
                                                                onClick={
                                                                    manageSubscription
                                                                }
                                                                type="primary"
                                                                className={
                                                                    classes.btn
                                                                }
                                                            >
                                                                {formatMessage({
                                                                    id: "plan.manage",
                                                                })}
                                                                <FaArrowCircleRight
                                                                    className="ml-2"
                                                                    fontSize={
                                                                        15
                                                                    }
                                                                />
                                                            </Button>
                                                        </div>
                                                    </Space>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            {pricingTables.map((item, idx) => (
                                <Fragment key={idx}>
                                    <CustomPaper>
                                        <div className="py-4">
                                            <PricingTable
                                                className="mt-3"
                                                pricingTableId={item?.tableId}
                                            />
                                        </div>
                                    </CustomPaper>
                                </Fragment>
                            ))}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(Subscription);
