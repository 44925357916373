import { FC } from "react";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { DialogActions, DialogTitle, LinearProgress } from "@material-ui/core";
import { Tooltip, IconButton, Typography } from "@material-ui/core";
import { Button, Space } from "antd";
import { useIntl } from "react-intl";
import useStyles from "./useStyles";
import confirmDelete from "../../images/confirm_delete.svg";
import CloseIcon from "@material-ui/icons/Close";
import SlideTransition from "../SlideTransition/SlideTransition";

interface Props {
    open: boolean;
    toggleModal(open: boolean): void;
    submit?: () => void;
    message?: string;
    loading?: boolean;
    title?: string;
}

const ConfirmDelete: FC<Props> = (props) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { open, toggleModal, submit, message, loading, title } = props;

    return (
        <Dialog
            open={open || false}
            maxWidth="sm"
            fullWidth
            keepMounted
            TransitionComponent={SlideTransition}
            PaperProps={{ style: { zIndex: 1 } }}
        >
            {loading && (
                <LinearProgress
                    classes={{
                        colorPrimary: classes.loader,
                        barColorPrimary: classes.loaderBar,
                    }}
                />
            )}
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Space>
                    <Typography className={classes.title} variant="h6">
                        {title ||
                            formatMessage({ id: "common.delete.confirmation" })}
                    </Typography>
                </Space>

                <IconButton
                    className={classes.closeButton}
                    size="small"
                    onClick={() => toggleModal(false)}
                    aria-label="close-icon"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <hr />
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div className="d-flex justify-content-center mb-4">
                    <img
                        src={confirmDelete}
                        height="100px"
                        width="auto"
                        alt="no data"
                    />
                </div>
                <DialogContentText className={classes.text}>
                    <br />
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                message ||
                                formatMessage({
                                    id: "common.confrm.delete.confirmation",
                                }),
                        }}
                    />
                </DialogContentText>

                <DialogActions className={classes.actions}>
                    <Tooltip
                        title={formatMessage({ id: "common.cancel" })}
                        arrow
                        placement="top"
                    >
                        <Button
                            className={classes.cancel}
                            onClick={() => toggleModal(false)}
                            disabled={loading}
                        >
                            {formatMessage({ id: "common.cancel" })}
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title={formatMessage({ id: "common.delete" })}
                        arrow
                        placement="top"
                    >
                        <Button
                            className={classes.submit}
                            onClick={submit}
                            disabled={loading}
                            loading={loading}
                        >
                            {formatMessage({ id: "common.delete.default" })}
                        </Button>
                    </Tooltip>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDelete;
