const categories = {
    "categories.title": "Catégories",
    "categories.category": "Catégorie",
    "categories.subcategory": "Sous-catégorie",
    "categories.add.category": "Ajouter une catégorie",
    "categories.add.subcategory": "Ajouter une sous-catégorie",
    "categories.create.title": "Création de catégorie",
    "categories.add.new": "Ajouter une nouvelle catégorie",
    "categories.add.new.subcategory": "Nouvelle sous-catégorie pour",
    "categories.edit": "Modifier la catégorie",
    "categories.all": "Toutes Catégories",
    "categories.name": "Nom",
    "categories.actions": "Actions",
    "categories.confirm.bulk.delete": "Confirmer la suppression en masse",
    "categories.confirm.delete.1": "Voulez-vous vraiment supprimer",
    "categories.confirm.delete.2": "Ce processus ne peut pas être annulé.",
    "categories.create.subtitle":
        "Entrez les informations de votre nouveau catégorie",
    "categories.add.success":
        "Une nouvelle catégorie a été ajoutée avec succès!",
    "categories.rename.success": "Renommé avec succès",
    "subcategories.create.title": "Création de sous-catégorie",
    "subcategories.create.subtitle":
        "Entrez les informations de votre nouveau sous-catégorie",
    "subcategories.add.success":
        "La nouvelle sous-catégorie a été ajoutée avec succès",
};

export default categories;
