const authentification = {
    "auth.signin.link": "Se connecter",
    "auth.signup.link": "S'inscrire",
    "auth.title": "Authentification à deux facteurs",
    "auth.signin.title": "Continuer l'authentification avec votre portefeuille",
    "auth.signin.subtitle":
        "Se connecter avec l'un de nos 'wallet providers' disponibles.",
    "auth.signin.id.two.factor.auth":
        "Connectez-vous avec votre compte ID et TracerKey",
    "auth.signin.id.two.factor.auth.mobile":
        "Login with your ID   and TracerKey",
    "auth.signin.tracerkey": "Se connecter avec TracerKey",
    "auth.signup.title": "S'inscrire avec votre portefeuille",
    "auth.signup.subtitle":
        "S'inscrire avec l'un de nos 'wallet providers' disponibles.",
    "auth.already.have.account": "Vous avez déjà un compte ?",
    "auth.no.account": "Pas de compte ?",
    "auth.signup.privacy.policy": "Politique de confidentialité",
    "auth.signup.privacy.policy.desc":
        "Vous acceptez les termes et conditions de la politique du site de TracerDoc et les ",
    "auth.signup.privacy.policy.desc.2": "de Tracerchain.",
    "auth.signup.terms.title": "Terms and Conditions",
    "auth.signup.terms.confirm.desc":
        "En cochant cette case, vous confirmez que vous avez lu et acceptez les",
    "auth.signup.terms.confirm.and": "et la ",
    "auth.signup.terms.confirm.tracerdoc": " de Tracerdoc.",
    "auth.signup.terms.tos": "Conditions d'utilisatio",
    "auth.signup.terms.privacy.policy": "Politique de confidentialité",
    "auth.signup.terms.cancel": "Annuler",
    "auth.signup.terms.continue": "Continuer",
    "auth.account.confirmation.title": "CONFIRMATION DE COMPTE TRACERDOC",
    "auth.account.confirmation.subtitle":
        "Cliquez sur le bouton ci-dessous pour confirmer et activer votre compte.",
    "auth.account.confirmed": "COMPTE CONFIRMÉ",
    "auth.resend.email": "Renvoyer l'e-mail de confirmation",
    "auth.resend.email.title": "Renvoyer un mail d'activation",
    "auth.resend.email.text":
        "Saisissez votre adresse e-mail pour renvoyer l'e-mail d'activation.",
    "auth.confirmation.email.sent.title": "MAIL DE CONFIRMATION ENVOYÉ",
    "auth.confirmation.email.sent.subtitle":
        "Veuillez confirmer votre compte. Un e-mail de confirmation a été envoyé à {email}, cliquez sur le lien pour confirmer votre compte.",
    "auth.logout": "Déconnexion",
    "auth.manage.account": "Gérer son compte",
    "auth.signin.view.doc.transfer.alert":
        "Vous devez vous connecter à votre compte pour visualiser le transfert de documents.",
    "auth.wallet.view.doc.transfer.alert":
        "Vous devez vous connecter à votre compte tracerkey pour visualiser le transfert de document.",
    "auth.install.tracerkey": "Comment installer tracerkey?",
    "auth.support.help.center": "Support - Centre d'aide",
    "logout.title": "Vous déconnecter...",
    "logout.header": "Confirmer la déconnexion",
    "logout.text": "Êtes-vous sûr de vouloir vous déconnecter ?",
    "logout.button": "Se déconnecter",
};

export default authentification;
