import { FC, useContext, useState } from "react";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GET_MY_PARTNERS } from "../../../graphql/partnership/query";
import { MyPartners } from "../../../graphql/partnership/types/MyPartners";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { Badge, Space } from "antd";
import { Grid } from "@material-ui/core";
import { RangeValue } from "../../../constants/types";
import { TABLE_PAGINATION_PAGE_SIZE } from "../../../constants/pageSize";
import { MyPartners_myPartners } from "../../../graphql/partnership/types/MyPartners";
import { DeletePartnership } from "../../../graphql/partnership/types/DeletePartnership";
import { DeletePartnershipVariables } from "../../../graphql/partnership/types/DeletePartnership";
import { DELETE_PARTNERSHIP } from "../../../graphql/partnership/mutation";
import { sweetalert } from "../../../utils/sweetalert";
import { filterListByDate } from "../../../components/DateFilter/DateFilter";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import TableLoader from "../../../components/TableLoader/TableLoader";
import CustomPaper from "../../../components/CustomPaper/CustomPaper";
import CustomTitle from "../../../components/CustomTitle/CustomTitle";
import CustomAntdTable from "../../../components/CustomAntdTable/CustomAntdTable";
import tableColumns from "./PartnersTableColumns";
import DateFilter from "../../../components/DateFilter/DateFilter";
import ConfirmDelete from "../../../components/ConfirmDelete/ConfirmDelete";

const Partners: FC<RouteComponentProps> = () => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const [search, setSearch] = useState<string>("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(TABLE_PAGINATION_PAGE_SIZE);
    const [dateFilter, setDateFilter] = useState<string>("all");
    const [openDateFilter, setOpenDateFilter] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<RangeValue>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedPartner, setSelectedPartner] =
        useState<MyPartners_myPartners | null>(null);

    const { data, loading } = useQuery<MyPartners>(GET_MY_PARTNERS, {
        onError: (err) => onError(err),
    });

    const [deletePartnership, { loading: deleteLoader }] = useMutation<
        DeletePartnership,
        DeletePartnershipVariables
    >(DELETE_PARTNERSHIP, {
        onCompleted: async () => {
            setOpenDeleteModal(false);
            setSelectedPartner(null);
            sweetalert(
                "success",
                "Deleted",
                `A partnership was deleted successfully!`
            );
        },
        onError: (error) => onError(error),
    });

    const onError = (error: ApolloError) => {
        displaySnackBar({
            message: error.message
                ? error.message
                : formatMessage({ id: "error.unknown" }),
            type: "error",
        });
    };
    const onPageChange = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const toggleDateFilter = (open: boolean) => {
        setOpenDateFilter(open);

        if (!open && dateFilter === "custom" && !dateRange) {
            setDateFilter("all");
        }
    };

    const handleDateFilter = (type: string) => {
        setDateFilter(type);
        if (type !== "custom") setDateRange(null);
    };

    const handleClickDelete = (partner: MyPartners_myPartners) => {
        setOpenDeleteModal(true);
        setSelectedPartner(partner);
    };

    const confirmDelete = () => {
        deletePartnership({
            variables: {
                partnershipId: Number(selectedPartner?.id),
            },
        });
    };

    const myPartners = data?.myPartners || [];
    let filteredPartners = myPartners?.length
        ? myPartners.filter((partner) => {
              const _search = search?.toLowerCase();
              return partner.partner.email.toLowerCase().startsWith(_search);
          })
        : null;

    filteredPartners = filteredPartners?.length
        ? filterListByDate(filteredPartners, dateFilter, dateRange, "createdAt")
        : [];

    if (loading) return <TableLoader />;

    return (
        <>
            <CustomPaper>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="my-1"
                >
                    <Grid item>
                        <Space>
                            <CustomTitle title={`partnership.partners.title`} />
                            <Badge
                                count={`${myPartners?.length || 0}`}
                                overflowCount={10000}
                                style={{
                                    marginTop: "-1em",
                                    backgroundColor: "#0B9F6F",
                                }}
                            />
                        </Space>
                    </Grid>
                    <Grid item className="d-flex justify-content-end">
                        <Space>
                            <SearchFilter
                                value={search}
                                setValue={setSearch}
                                results={filteredPartners?.length}
                                placeholder={formatMessage({
                                    id: "partnership.searchPartner",
                                })}
                            />
                            <DateFilter
                                openDateFilter={openDateFilter}
                                dateFilter={dateFilter}
                                toggleDateFilter={toggleDateFilter}
                                handleDateFilter={handleDateFilter}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Space>
                    </Grid>
                </Grid>

                <CustomAntdTable
                    data={filteredPartners}
                    rowKey="id"
                    childrenColumnName="subCategories"
                    pagination
                    onPageChange={onPageChange}
                    selectedRow={selectedPartner}
                    selectedKey="id"
                    columns={tableColumns(page, pageSize, handleClickDelete)}
                />
            </CustomPaper>
            <ConfirmDelete
                open={openDeleteModal}
                toggleModal={setOpenDeleteModal}
                submit={confirmDelete}
                loading={deleteLoader}
                message={`${formatMessage({
                    id: "partnership.confirm.delete.1",
                })} ${selectedPartner?.partner?.email || ""}? ${formatMessage({
                    id: "partnership.confirm.delete.2",
                })}`}
            />
        </>
    );
};

export default withRouter(Partners);
