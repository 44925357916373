const document = {
    "document.list.title": "Document list",
    "document.transfer.title": "Document transfer",
    "document.transfer.submit": "Encrypt, Upload and Transfer",
    "document.personal.submit": "Encrypt and Upload",
    "document.list.decrypt.download": "Download and Decrypt",
    "document.list.decrypt.download.tooltip":
        "Download and Decrypt the document",
    "document.decryption.title": "Decryption",
    "document.decryption.section.title": "Simple document Decryption",
    "document.decryption.subtitle":
        "Here you can simply decrypt a file. Please note that only file encrypted by TracerDoc (.tdfile extension) can be decrypted here.",
    "document.encryption.submit": "Encrypt and Download",
    "document.decryption.submit": "Decrypt",
    "document.list.download": "Download",
    "document.list.download.tooltip": "Download the encrypted document",
    "document.encryption.title": "Document encryption",
    "document.encryption.subtitle":
        "Choose a document and encrypt it with your partner's key.",
    "document.transfer.mode": "Transfer mode",
    "document.transfer.mode.upload.desc":
        "Upload the encrypted document via IPFS and transfer to the partner.",
    "document.transfer.mode.download.desc":
        "Simply download the encrypted document.",
    "document.transfer.mode.upload": "Upload",
    "document.transfer.mode.upload.and.transfer": "Upload and Transfer",
    "document.transfer.mode.download": "Encrypt and Download only",
    "document.transfer.type.public": "Public",
    "document.transfer.type.private.ipfs": "Private IPFS",
    "document.transfer.type.private": "Private",
    "document.transfer.type": "IPFS type",
    "documents.all": "All Documents",
    "document.public.storage.max.limit":
        "Public storage has reached its maximum file limit!",
    "document.private.storage.max.limit":
        "Private storage has reached its maximum file limit! Upgrade your subscription to increase your volume.",
    "document.upgrade.license":
        "Upgrade your license to upload and transfer documents.",
    "document.download.decrypt": "Download and Decrypt",
    "document.properties": "Document Properties",
    "document.fileName": "Filename",
    "document.fileSize": "Size",
    "document.securityLevel": "Security Level",
    "document.ipfsType": "IPFS Type",
    "document.cid": "Document CID",
    "document.sender.cid": "Sender Key CID",
    "document.recipient.cid": "Recipient Key CID",
    "document.created": "Created",
    "document.expiresAt": "Will expire on",
    "document.modified": "Modified",
    "document.category": "Category",
    "document.subcategory": "Subcategory",
    "document.categories": "Categories",
    "document.sender": "Sender",
    "document.recipient": "Recipient",
    "document.status": "Status",
    "document.transfer.new": "Add New Document",
    "document.download.url": "Download URL",
    "document.remove.filter": "Click here to remove filter to view all list",
    "document.alert.remove.filter":
        "Click the filter icon below to view the list of document transfers.",
    "document.download.only": "Download Only",
    "document.select.action": "Select Action",
    "document.upload.files": "Upload tracerdoc file and key",
    "document.upload.zip": "Upload zip file",
    "documents.personal": "Internal Storage",
    "document.no.storage": "No Storage",
    "document.add.new.modal.title": "Add New Document",
    "document.download.encrypted.file": "Download Encrypted File",
    "document.store.encrypted.file": "Store Encrypted File",
    "document.store.share.encrypted.file": "Store and Share Encrypted File",
    "document.download.shareable.file": "Download the shareable encrypted file",
    "document.remove": "Remove file",
    "document.no.partner": "No Partner - Only for me",
    "document.duration": "Expiration",
    "document.unlimited": "Unlimited",
    "document.d001": "1 day (24 hours)",
    "document.d003": "3 days (72 hours)",
    "document.d007": "7 days (168 hours)",
    "document.d030": "30 days (720 hours)",
    "document.permanent.storage": "* Permanent Storage",
    "document.storage.after.upload": "* Storage available after upload ",
    "document.auto.remove.activated": "* Auto remove activated at",
    "document.not.available.free.license": "Not available for free license",
    "document.confirm.delete.1": "Are you sure you want to delete ",
    "document.confirm.delete.2":
        "This will be removed permanently for you and your partners.",
};

export default document;
