import { FC, ReactNode } from "react";
import { Grid, Paper, Typography, Container } from "@material-ui/core";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { Alert, Button, Space } from "antd";
import { Badge } from "react-bootstrap";
import logo from "../../images/logo.png";
import docTransferVector from "../../images/doc-transfer.png";
import clsx from "clsx";
import LanguageOptions from "../../components/LanguageOptions/LanguageOptions";
import useStyles from "./styles";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";
import Fade from "react-reveal/Fade";
import SEO from "../../components/SEO/SEO";

interface Props {
    children: ReactNode;
    type: string;
    title: string;
    noTracerkey?: boolean;
    pageTitle?: string;
}

const AuthLayout: FC<RouteComponentProps & Props> = (props) => {
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const { formatMessage } = useIntl();
    const { history, location, children, type, title, noTracerkey } = props;
    const { pageTitle } = props;

    const locationState =
        location?.state && typeof location?.state === "string"
            ? JSON.parse(`${location?.state}`)
            : "";
    const locationStateFrom = locationState?.from || "";

    return (
        <>
            <SEO title={pageTitle} />
            <div className={classes.root}>
                <Container maxWidth="lg" className="h-100">
                    <Fade bottom distance="15%">
                        <div className="w-100 py-4 d-flex justify-content-between">
                            <Link to="/">
                                <img
                                    src={logo}
                                    height="40px"
                                    width="auto"
                                    alt="tclogo"
                                />
                            </Link>
                            <Space size={20}>
                                {breakpoint !== "xs" && (
                                    <LanguageOptions isOnAppBar />
                                )}
                                <Button
                                    type="link"
                                    shape="circle"
                                    size="large"
                                    onClick={() => {}}
                                    className={classes.iconBtn}
                                    href="https://wiki.tracerdoc.com"
                                    target="_blank"
                                >
                                    {formatMessage({
                                        id: "auth.support.help.center",
                                    })}
                                </Button>
                            </Space>
                        </div>
                    </Fade>

                    <div className={classes.container}>
                        <Grid
                            container
                            spacing={4}
                            className="h-100 d-flex align-items-center"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={7}
                                className={classes.main}
                            >
                                <Fade bottom>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        align="left"
                                        className={clsx(
                                            classes.globalText,
                                            classes.desc1
                                        )}
                                    >
                                        {formatMessage({ id: "home.title.1" })}{" "}
                                        <br className={classes.compliance} />
                                        <span className={classes.subtitle}>
                                            {formatMessage({
                                                id: "home.title.2",
                                            })}
                                        </span>
                                    </Typography>
                                </Fade>

                                <Fade bottom delay={150} distance="15%">
                                    <div className={classes.transferVectorCont}>
                                        <img
                                            src={docTransferVector}
                                            className={classes.transferVector}
                                            alt="document transfer"
                                        />
                                    </div>
                                </Fade>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={5}
                                className={classes.formGridItem}
                            >
                                <Fade bottom delay={150} distance="10%">
                                    <Paper
                                        elevation={0}
                                        className={classes.paper}
                                        style={{
                                            boxShadow:
                                                breakpoint !== "xs"
                                                    ? "2px 1px 192px -5px rgba(223,225,240,1)"
                                                    : "",
                                        }}
                                    >
                                        {type !== "wallet-auth" && (
                                            <>
                                                <Badge
                                                    pill
                                                    style={{
                                                        fontSize: "14px",
                                                        padding: "0.6em 2em",
                                                        background:
                                                            type === "signup"
                                                                ? "#3980F0"
                                                                : "#D6F4FE",
                                                        color:
                                                            type === "signup"
                                                                ? "#fff"
                                                                : "#1E5B99",
                                                        fontWeight: 400,
                                                        margin: "2em 0 0 2em",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        history.push(
                                                            ROUTES.SIGNUP
                                                        )
                                                    }
                                                >
                                                    {formatMessage({
                                                        id: "auth.signup.link",
                                                    })}
                                                </Badge>
                                                <Badge
                                                    pill
                                                    onClick={() =>
                                                        history.push(
                                                            ROUTES.SIGNIN
                                                        )
                                                    }
                                                    style={{
                                                        fontSize: "14px",
                                                        padding: "0.6em 2em",
                                                        background:
                                                            type === "signin"
                                                                ? "#3980F0"
                                                                : "#D6F4FE",
                                                        color:
                                                            type === "signin"
                                                                ? "#fff"
                                                                : "#1E5B99",

                                                        fontWeight: 400,
                                                        margin: "2em 0 0 -1.4em",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {formatMessage({
                                                        id: "auth.signin.link",
                                                    })}
                                                </Badge>
                                            </>
                                        )}
                                        <div className={classes.children}>
                                            {((locationStateFrom &&
                                                locationStateFrom?.pathname?.includes(
                                                    ROUTES.DOCUMENTS
                                                ) &&
                                                locationStateFrom?.search) ||
                                                (location?.search &&
                                                    !location?.state)) && (
                                                <div className="w-100 mb-4">
                                                    <Alert
                                                        description={formatMessage(
                                                            {
                                                                id:
                                                                    location?.search &&
                                                                    !location?.state
                                                                        ? "auth.wallet.view.doc.transfer.alert"
                                                                        : "auth.signin.view.doc.transfer.alert",
                                                            }
                                                        )}
                                                        type="warning"
                                                        showIcon
                                                        className={
                                                            classes.alertWarning
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {noTracerkey && (
                                                <div className="w-100 mb-4">
                                                    <Alert
                                                        description={formatMessage(
                                                            {
                                                                id: "error.not.installed.tracerkey",
                                                            }
                                                        )}
                                                        type="error"
                                                        showIcon
                                                    />
                                                </div>
                                            )}
                                            <div className="w-100">
                                                <Typography
                                                    component="h1"
                                                    variant="h6"
                                                    className={
                                                        classes.childrenTitle
                                                    }
                                                >
                                                    {formatMessage({
                                                        id: title,
                                                    }).toUpperCase()}
                                                </Typography>

                                                {children}
                                            </div>
                                        </div>
                                    </Paper>
                                </Fade>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default withRouter(AuthLayout);
