import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import {
    ethSign,
    isConnected,
    mldsaSign,
    tracerkeyDisconnect,
} from "../../services/tracerkey";
import { mlkDecrypt, tracerkeyConnect } from "../../services/tracerkey";
// import { signAuthMessageTracerkey } from "../../services/tracerkey";
import { tracerkeyCheckExtension } from "../../services/tracerkey";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { useMutation } from "@apollo/client";
import { WALLET_AUTH } from "../../graphql/authentification/mutation";
import { WalletAuthVariables } from "../../graphql/authentification/types/WalletAuth";
import { WalletAuth } from "../../graphql/authentification/types/WalletAuth";
import { DOCUMENT_TAB_ROUTES } from "../Document/Document";
import { WalletAuthAction } from "../../types/graphql-global-types";
import { AuthContext } from "../../providers/AuthProvider/AuthProvider";
import { AuthContext as Oauth2Context } from "react-oauth2-code-pkce";
import { DEVID } from "../../constants/devIdEndpoints";
import config from "../../config/config";
import useStyles from "./styles";
import IdAccount from "../../components/IdAccount/IdAccount";
// import LogoutIframe from "../../components/LogoutIframe/LogoutIframe";
import CustomButton from "../../components/CustomButton/CustomButton";
import AuthLayout from "./AuthLayout";
import axios from "axios";
import { clearStorage } from "../../utils/clearStorage";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import { Typography } from "@material-ui/core";
// import LogoutDialog from "../../components/ConfirmDelete/LogoutDialog";

const WalletAuthPage: FC<RouteComponentProps> = ({ history, location }) => {
    const classes = useStyles();
    const { formatMessage, locale } = useIntl();
    const { login } = useContext(AuthContext);
    const { meId } = useContext(UserContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [isTracerkeyInstalled, setIsTracerkeyInstalled] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [loader, setLoader] = useState(false);
    // const [logoutClick, setLogoutClick] = useState(false);
    const { token: accessToken } = useContext(Oauth2Context);

    const locationState =
        location?.state && typeof location?.state === "string"
            ? JSON.parse(`${location?.state}`)
            : "";
    const locationStateFrom = locationState?.from || "";

    // const displayError = (messageId: string) => {
    //     displaySnackBar({
    //         message: formatMessage({ id: messageId }),
    //         type: "error",
    //     });
    // };

    // useEffect(() => {
    //     (async () => {
    //         console.log("meId", meId);
    //         if (!accessToken) {
    //             history.push(ROUTES.SIGNIN);
    //         }
    //         // const installed = await tracerkeyCheckExtension();
    //         // setIsTracerkeyInstalled(installed);
    //     })();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        (async () => {
            // const installed = await tracerkeyCheckExtension();
            const checkExtension: any = await tracerkeyCheckExtension();
            setIsTracerkeyInstalled(checkExtension?.installed);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [walletAuth, { loading }] = useMutation<
        WalletAuth,
        WalletAuthVariables
    >(WALLET_AUTH, {
        onCompleted: async (data) => {
            if (data.walletAuth.token) {
                isConnected(true);
                login(data.walletAuth.token);

                if (
                    locationStateFrom &&
                    locationStateFrom?.pathname?.includes(ROUTES.DOCUMENTS) &&
                    locationStateFrom?.search
                ) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${locationStateFrom?.search}`
                    );
                } else if (location?.search && !location?.state) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${location?.search}`
                    );
                } else {
                    history.replace(ROUTES.DASHBOARD);
                }
                // SIGNIN
                if (data.walletAuth.action === WalletAuthAction.SIGNIN) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.login" }),
                        type: "success",
                    });
                }
                // SIGNUP
                if (
                    data.walletAuth.action === WalletAuthAction.SIGNUP &&
                    data.walletAuth.createdUserId
                ) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.signup" }),
                        type: "success",
                    });
                }
            }
        },

        onError: async (error) => {
            if (!error?.message?.includes("gateway")) {
                displaySnackBar({
                    message: error?.message
                        ? error?.message
                        : formatMessage({
                              id: error.message || "error.unknown",
                          }),
                    type: "error",
                });
            }
        },
    });

    const createClientChallenge = async (keys: any) => {
        const { publicKey, mlkem_public_key, mldsa_public_key } = keys || {};
        const body = {
            eth_public_key: publicKey,
            mlkem_public_key,
            mldsa_public_key,
        };
        console.log("v1/client/challenge payload", body);

        const url = `${config.id.walletUrl}${DEVID.CLIENTCHALLENGE}`;
        const headers = { Authorization: `Bearer ${accessToken}` };
        const res = await axios.post(url, body, { headers });

        console.log("v1/client/challenge response", res);
        return res;
    };

    const createClient = async (keys: any) => {
        const clientUrl = `${config.id.walletUrl}${DEVID.CLIENT}`;
        const {
            publicKey: eth_public_key,
            mlkem_public_key,
            mldsa_public_key,
        } = keys || {};

        const clientChallenge = await createClientChallenge(keys);
        const {
            eth_challenge,
            mldsa_challenge,
            mlkem_challenge_ciphertext,
            mlkem_shared_key_ciphertext,
        } = clientChallenge?.data || {};

        if (!clientChallenge?.data) {
            throw new Error("Failed to create client challenge.");
        }

        try {
            const [ethSignRes, mldsaSignRes, mlkemDecryptRes] =
                await Promise.all([
                    ethSign(eth_challenge, true),
                    mldsaSign(mldsa_challenge),
                    mlkDecrypt(
                        mlkem_challenge_ciphertext,
                        mlkem_shared_key_ciphertext
                    ),
                ]);

            const { signature: eth_challenge_signature } = ethSignRes || {};
            const { sig: mldsa_challenge_signature } = mldsaSignRes || {};
            const mlkem_challenge = mlkemDecryptRes?.plaintext;

            console.log("ethSign", ethSignRes);
            console.log("mldsaSig", mldsaSignRes);
            console.log("mldsaSig", mlkemDecryptRes);
            if (
                eth_challenge_signature &&
                mldsa_challenge_signature &&
                mlkem_challenge
            ) {
                const clientData = {
                    name: "My wallet",
                    eth_public_key,
                    eth_challenge,
                    eth_challenge_signature,
                    mldsa_public_key,
                    mldsa_challenge,
                    mldsa_challenge_signature,
                    mlkem_public_key,
                    mlkem_challenge,
                };

                const headers = {
                    Authorization: `Bearer ${accessToken}`,
                };

                // Send client data to the server
                const clientRes = await axios.post(clientUrl, clientData, {
                    headers,
                });

                if (clientRes?.status === 200) {
                    return { eth_challenge, eth_challenge_signature };
                } else {
                    throw new Error("Failed to register the client.");
                }
            } else {
                throw new Error("One or more signatures/decryptions failed.");
            }
        } catch (error) {
            console.error("Error in createClient:", error.message);
            throw error;
        }
    };

    const onClickWalletAuth = async () => {
        if (meId && meId?.email && meId?.sub) {
            const keys = await tracerkeyConnect();

            const { eth_challenge, eth_challenge_signature } =
                await createClient(keys);
            console.log("keys", keys);

            try {
                if (eth_challenge && eth_challenge_signature) {
                    walletAuth({
                        variables: {
                            input: {
                                message: eth_challenge,
                                signature: eth_challenge_signature,
                                email: meId?.email,
                                keycloakUserId: meId?.sub,
                            },
                        },
                    });
                }
            } catch (error) {
                console.error("Error creating challenge:", error);
            }
        }
    };

    // const getChallenge = async () => {
    //     const { address } = await tracerkeyConnect();

    //     console.log(address);

    //     const url = `${config.id.walletUrl}${DEVID.CLIENTCHALLENGE}`;
    //     // const wallet = ethers.Wallet.createRandom();
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //         // subject: meId?.sub,
    //         // "Content-Type": "application/json",
    //     };
    //     console.log(headers);
    //     const data = { address };

    //     try {
    //         const result = await axios.post(url, data, { headers });
    //         // setResponse(result.data);
    //         const { challenge } = result?.data || {};
    //         console.log(challenge);

    //         if (challenge) {
    //         }
    //     } catch (error) {
    //         console.error("Error creating challenge:", error);
    //         // setResponse(error.response ? error.response.data : 'Error');
    //     }
    // };

    // const importWallet = async () => {
    //     const url = `${config.id.walletUrl}${DEVID.SERVERIMPORT}`;
    //     const wallet = ethers.Wallet.createRandom();
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //         // subject: meId?.sub,
    //         "Content-Type": "application/json",
    //     };
    //     console.log("Headers", headers);
    //     const data = {
    //         name: "3",
    //         private_key: wallet.privateKey,
    //     };

    //     try {
    //         const result = await axios.post(url, data, { headers });
    //         // setResponse(result.data);
    //         console.log(result);
    //     } catch (error) {
    //         console.error("Error importing wallet:", error);
    //         // setResponse(error.response ? error.response.data : 'Error');
    //     }
    // };

    const devIdLogout = async () => {
        setLoader(true);
        clearStorage().then(async () => {
            tracerkeyDisconnect().then(() => {
                window.location.href = `${
                    config?.id?.baseUrl
                }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`;
            });
        });

        // *****************************
        // clearStorage().then(() => {
        // setLogoutClick(true);
        // setTimeout(() => {
        //     window.location.href = ROUTES.LOGOUT;
        //     setLogoutClick(false);
        // }, 5000);

        // window.location.href = `${
        //     config?.id?.baseUrl
        // }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`;

        // *****************************
        // const newTab = window.open(
        //     `${
        //         config?.id?.baseUrl
        //     }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`,
        //     "_blank",
        //     "noopener,noreferrer"
        // );

        // if (newTab) {
        //     setTimeout(() => {
        //         newTab.blur();
        //         window.focus();

        //         window.location.href = ROUTES.LOGOUT;
        //     }, 5000); // Close after 5 seconds
        // }
        // *****************************

        // fetch("http://localhost:3001/api/logout", {
        //     method: "GET",
        //     credentials: "include", // Ensures cookies are sent with the request
        // })
        //     .then((response) => response.json())
        //     .then((data) => console.log("Logout response:", data))
        //     .catch((error) => console.error("Logout error:", error));

        // await axios
        //     // .get(config?.id?.userInfoUrl as string, {
        //     .get(`${config?.id?.baseUrl}/api/logout` as string, {
        //         headers: {
        //             Authorization: `Bearer ${accessToken}`,
        //             "Content-Type": "application/json",
        //         },
        //     })
        //     .then((res) => {
        //         console.log(res);
        //     });

        // *****************************

        // const iframe = document.createElement("iframe");
        // iframe.src = `${
        //     config?.id?.baseUrl
        // }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`;
        // // iframe.style.display = "none"; // Hide the iframe so it's not visible to the user
        // document.body.appendChild(iframe);

        // // Optionally, remove the iframe after 5 seconds (or any other action)
        // setTimeout(() => {
        //     document.body.removeChild(iframe); // Clean up the DOM
        //     window.location.href = ROUTES.LOGOUT;
        // }, 5000);
        // });

        // clearStorage().then(async () => {
        //     // const response = await fetch(
        //     //     `${
        //     //         config?.id?.baseUrl
        //     //     }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`

        //     // );
        //     // const result = await response;

        //     // console.log(result);

        //     // setTimeout(() => {
        //     //     window.location.href = ROUTES.LOGOUT;
        //     //     // setLogoutClick(false);
        //     // }, 5000);

        //     fetch("http://localhost:3001/api/logout", {
        //         method: "GET",
        //         credentials: "include", // Ensures cookies are sent with the request
        //     })
        //         .then((response) => response.json())
        //         .then((data) => console.log("Logout response:", data))
        //         .catch((error) => console.error("Logout error:", error));
        // });
    };

    return (
        <AuthLayout
            type="wallet-auth"
            title="auth.signin.title"
            noTracerkey={!isTracerkeyInstalled}
            pageTitle="Connect Wallet"
        >
            <IdAccount />

            <form className={classes.form} noValidate>
                <CustomButton
                    // onClick={devIdLogout}
                    onClick={() => setLogoutModal(true)}
                    text={formatMessage({
                        id: "common.id.logout",
                    })}
                    // disabled={loading || logoutClick}
                    disabled={loading}
                    bordered
                    fullWidth
                    style={{ borderRadius: "25px" }}
                    // loading={logoutClick}
                />
                <CustomButton
                    onClick={onClickWalletAuth}
                    text={formatMessage({
                        id: "auth.signin.tracerkey",
                    })}
                    fullWidth
                    loading={loading}
                    // disabled={!isTracerkeyInstalled || logoutClick}
                    disabled={!isTracerkeyInstalled}
                    style={{ borderRadius: "25px" }}
                />

                <div className="d-flex justify-content-end mt-4">
                    <a
                        className={classes.installLink}
                        href={config.tracerkeyInstallationUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {formatMessage({ id: "auth.install.tracerkey" })}
                    </a>
                </div>
            </form>
            {/* <iframe
                src={
                    logoutClick
                        ? `${
                              config?.id?.baseUrl
                          }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`
                        : ""
                }
                title="id logout"
                style={{
                    width: 500,
                    height: 500,
                    border: "none",
                }}
                sandbox="allow-scripts allow-same-origin"
            ></iframe> */}

            <CustomDialog
                loading={loader}
                open={logoutModal}
                handleClose={() => setLogoutModal(false)}
                title={formatMessage({ id: "logout.header" })}
                submitText={formatMessage({ id: "logout.button" })}
                handleSubmit={devIdLogout}
                disableSubmit={false}
                fullWidth
                buttonWidth={locale === "fr" ? "35%" : "25%"}
            >
                <Typography variant="subtitle1" className={classes.logout}>
                    {formatMessage({ id: "logout.text" })}
                </Typography>
            </CustomDialog>
        </AuthLayout>
    );
};

export default withRouter(WalletAuthPage);
